import React, { Fragment, memo, useEffect, useRef, useState } from 'react';
import {
    Wrapper,
    StyledActions,
    StyledBackdrop,
    StyledCloseButton,
    StyledContent,
    StyledFooter,
    StyledHeader,
    StyledInner,
} from './styled';
import Close from '$icons/close-bold.svg';
import { Headline } from '~/templates/blocks/components/Headline/Headline';
import { Icon, Portal, ScrollArea } from '$shared/components';
import { useScrollLock } from '$shared/hooks';
import { getLocaleDirection, useTranslation } from '~/shared/utils/translation';
import { useId } from '@radix-ui/react-id';

interface Props {
    children?: React.ReactNode;
    direction?: 'left' | 'right';
    footerContent?: React.ReactNode | React.ReactNode[];
    open: boolean;
    onClose: () => void;
    title?: string;
    maxWidth?: number;
    withSSR?: boolean;
    sidePadding?: 'none' | 'default' | 'large';
    hideHeader?: boolean;
    transparentBackdrop?: boolean;
    culture?: string;
}

interface ContentProps extends Partial<Props> {
    active: boolean;
    labelledbyId: string;
    setActive: (active: boolean) => void;
}

const ContentMemo = memo(
    ({
        onClose,
        children,
        title,
        footerContent,
        maxWidth = 675,
        sidePadding = 'default',
        hideHeader = false,
        transparentBackdrop = false,
        labelledbyId,
    }: ContentProps) => {
        const footerContentAsList = Array.isArray(footerContent) ? footerContent : [footerContent];
        const { translate } = useTranslation();
        return (
            <StyledContent
                className="sidepanel-content"
                maxWidth={maxWidth}
                withBoxShadow={transparentBackdrop}
            >
                {!hideHeader && (
                    <StyledHeader style={title ? {} : { paddingBottom: 0 }}>
                        <StyledActions>
                            <StyledCloseButton
                                onClick={onClose}
                                aria-label={translate('Kompan.Generic.Close')}
                            >
                                <Icon size="sm">
                                    <Close />
                                </Icon>
                            </StyledCloseButton>
                        </StyledActions>
                        {title && (
                            <Headline variant="display3" id={labelledbyId}>
                                {title}
                            </Headline>
                        )}
                    </StyledHeader>
                )}

                <ScrollArea>
                    <StyledInner sidePadding={sidePadding}>{children}</StyledInner>
                </ScrollArea>
                {footerContentAsList.length > 0 && (
                    <StyledFooter sidePadding={sidePadding}>
                        {footerContentAsList.map((node, index) => (
                            <Fragment key={index}>{node}</Fragment>
                        ))}
                    </StyledFooter>
                )}
            </StyledContent>
        );
    },
);

const getLocaleDrawerDirection = (culture: string) => {
    return getLocaleDirection(culture) === 'rtl' ? 'right' : 'left';
};

export function Sidepanel({
    open,
    onClose,
    children,
    direction,
    title,
    footerContent,
    maxWidth = 675,
    withSSR = false,
    sidePadding = 'default',
    hideHeader = false,
    transparentBackdrop = false,
    culture = 'en',
}: Props) {
    const { lock, unlock } = useScrollLock();

    const [active, setActive] = useState(open);
    const [isHidden, setIsHidden] = useState(open);
    const [showSSR, setShowSSR] = useState(true);
    const contentRef = useRef<HTMLDivElement | null>(null);
    const [triggerElement, setTriggerElement] = useState<HTMLElement | null>(null);

    const directionToRender = direction ?? getLocaleDrawerDirection(culture);

    useEffect(() => {
        /**
         * This will be set as soon as we're in client.
         * By doing this we can render the content directly in the server response.
         * The content will never be shown to the user.
         */
        setShowSSR(false);
    }, []);

    useEffect(() => {
        const keyHandler = (e: KeyboardEvent) => {
            if (e.key === 'Escape' && onClose) {
                onClose();
            }
        };
        open && window.addEventListener('keyup', keyHandler);
        if (open) {
            setIsHidden(false);
            window.setTimeout(() => {
                const { activeElement } = document;
                if (activeElement instanceof HTMLElement) {
                    setTriggerElement(activeElement);
                    activeElement.blur();
                }
                setActive(open);
                lock();
                // Set focus to SidePanel content
                if (contentRef.current) {
                    contentRef.current.focus();
                }
            }, 10);
        } else {
            setActive(open);
            window.setTimeout(() => {
                setIsHidden(true);
            }, 250);
            unlock();
            // Set focus back to whatever opened the SidePanel in the first place
            if (triggerElement) {
                triggerElement.focus();
            }
        }

        return () => {
            window.removeEventListener('keyup', keyHandler);
        };
    }, [open]);

    const labelledbyId = useId();

    if (withSSR && showSSR)
        return (
            <Wrapper
                open={false}
                hidden={true}
                direction={directionToRender}
                tabIndex={0}
                role="dialog"
                aria-modal="true"
                aria-labelledby={title ? labelledbyId : undefined}
            >
                <StyledBackdrop onClick={onClose} transparentBackdrop={transparentBackdrop} />

                <ContentMemo
                    setActive={setActive}
                    active={false}
                    onClose={onClose}
                    labelledbyId={labelledbyId}
                    direction={directionToRender}
                    title={title}
                    footerContent={footerContent}
                    maxWidth={maxWidth}
                    children={children}
                    sidePadding={sidePadding}
                    hideHeader={hideHeader}
                    transparentBackdrop={transparentBackdrop}
                />
            </Wrapper>
        );

    return (
        <Portal>
            <Wrapper
                open={active}
                hidden={isHidden}
                direction={directionToRender}
                tabIndex={0}
                role="dialog"
                aria-modal="true"
                aria-labelledby={title ? labelledbyId : undefined}
            >
                <StyledBackdrop onClick={onClose} transparentBackdrop={transparentBackdrop} />

                <ContentMemo
                    setActive={setActive}
                    active={active}
                    onClose={onClose}
                    labelledbyId={labelledbyId}
                    direction={directionToRender}
                    title={title}
                    footerContent={footerContent}
                    maxWidth={maxWidth}
                    children={children}
                    sidePadding={sidePadding}
                    hideHeader={hideHeader}
                    transparentBackdrop={transparentBackdrop}
                />
            </Wrapper>
        </Portal>
    );
}
